import { Badge, Button, Card, Col, Row, Typography } from "antd";
import { SolutionOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearPurchase,
  deletePurchase,
  loadSinglePurchase,
} from "../../redux/rtk/features/purchase/purchaseSlice";
import CardComponent from "../Card/card.components";
import PurchaseProductListCard from "../Card/purchaseInvoice/PurchaseProductListCard";
import ReturnPurchaseInvoiceList from "../Card/purchaseInvoice/ReturnPurchaseInvoiceList";
import TransactionPurchaseList from "../Card/purchaseInvoice/TransactionPurchaseList";
import PurchaseInvoice from "../Invoice/PurchaseInvoice";
import Loader from "../loader/loader";

const DetailsPurch = () => {
  const { id } = useParams();
  let navigate = useNavigate();

  //dispatch
  const dispatch = useDispatch();
  const purchase = useSelector((state) => state.purchases.purchase);
  console.log(purchase);
  const {
    status,
    totalPaidAmount,
    totalReturnAmount,
    dueAmount,
    singlePurchaseInvoice,
    returnPurchaseInvoice,
    transactions,
  } = purchase ? purchase : {};

  const totalAmount = singlePurchaseInvoice ? +dueAmount : 0;
  console.log("totalAmount", totalAmount);
  const purchaseInvoiceVat = singlePurchaseInvoice?.purchaseInvoiceVat || [];

  //Delete Supplier
  const onDelete = () => {
    try {
      dispatch(deletePurchase(id));

      setVisible(false);
      toast.warning(`Purchase : ${singlePurchaseInvoice.id} is removed `);
      return navigate("/admin/purchaselist");
    } catch (error) {
      console.log(error.message);
    }
  };
  // Delete Supplier PopUp
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    dispatch(loadSinglePurchase(id));
    return () => {
      dispatch(clearPurchase());
    };
  }, [dispatch, id]);

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }
  const taxInformation =
    singlePurchaseInvoice?.purchaseInvoiceVat?.productVat || [];
  console.log(singlePurchaseInvoice?.purchaseInvoiceProduct);
  return (
    <div>
      <div className="mr-top">
        {singlePurchaseInvoice ? (
          <Fragment key={singlePurchaseInvoice.id}>
            <Card bordered={false} className="criclebox h-full">
              <div className="flex justify-between">
                <h5>
                  <SolutionOutlined />
                  <span className="mr-left">
                    ID : {singlePurchaseInvoice.id} |
                  </span>
                </h5>
                <div className="card-header flex justify-between">
                  <div className="mr-4">
                    <Link
                      to={`/admin/payment/supplier/${singlePurchaseInvoice.id}`}
                      state={{ dueAmount: dueAmount }}
                    >
                      <Button
                        type="primary"
                        className="btn-sm"
                        disabled={!dueAmount}
                      >
                        Payment
                      </Button>
                    </Link>
                  </div>
                  {/* <div className='mr-4'>
										<Link to={`/admin/purchase/return/${id}`}>
											<Button type='primary' shape='round'>
												{" "}
												Return Product{" "}
											</Button>
										</Link>
									</div> */}

                  {/* <div className={"text-end mr-4"}>
										<PurchaseInvoice data={singlePurchaseInvoice} />
									</div> */}
                </div>
              </div>
              <div className="card-body mt-4">
                <Row justify="space-around">
                  <Col span={11}>
                    <Badge.Ribbon
                      text={totalAmount === 0 ? "Paid" : "Unpaid"}
                      color={totalAmount === 0 ? "green" : "red"}
                    >
                      <CardComponent title="Purchase Invoice Information ">
                        <h5 className="text-center mt-2 mb-2">
                          {" "}
                          Initial Invoice Info{" "}
                        </h5>
                        <p>
                          <Typography.Text strong>
                            Total Amount :
                          </Typography.Text>{" "}
                          <strong>{singlePurchaseInvoice.totalAmount}</strong>
                        </p>

                        <p>
                          <Typography.Text strong>Due Amount :</Typography.Text>{" "}
                          <strong className="text-danger">
                            {" "}
                            {singlePurchaseInvoice.dueAmount}
                          </strong>
                        </p>

                        <p>
                          <Typography.Text strong>
                            Paid Amount :
                          </Typography.Text>{" "}
                          <strong>{singlePurchaseInvoice.paidAmount}</strong>
                        </p>

                        <p>
                          <Typography.Text strong>Discount :</Typography.Text>{" "}
                          <strong>{singlePurchaseInvoice.discount}</strong>
                        </p>

                        <p>
                          <Typography.Text strong>Tax :</Typography.Text>{" "}
                          {purchaseInvoiceVat.map((vat, index) => (
                            <Fragment key={index}>
                              <strong>
                                {vat.productVat.title}{" "}
                                {vat.productVat.percentage}%
                              </strong>
                              {index < purchaseInvoiceVat.length - 1 && ", "}
                            </Fragment>
                          ))}
                        </p>

                        <p>
                          <Typography.Text strong>
                            Purchase Date :
                          </Typography.Text>{" "}
                          <strong>
                            {singlePurchaseInvoice.createdAt.slice(0, 10)}
                          </strong>
                        </p>

                        <p>
                          <Typography.Text strong>
                            Supplier Memo No :
                          </Typography.Text>{" "}
                          <strong>
                            {singlePurchaseInvoice.supplierMemoNo}
                          </strong>
                        </p>

                        <p>
                          <Typography.Text strong>
                            Invoice Number :
                          </Typography.Text>{" "}
                          <strong>
                            {singlePurchaseInvoice.invoiceNumber || ""}
                          </strong>
                        </p>

                        <p>
                          <Typography.Text strong>Note :</Typography.Text>{" "}
                          <strong>{singlePurchaseInvoice.note}</strong>
                        </p>

                        <div>
                          <h5 className="text-center mt-2 mb-2">
                            Update Invoice Info
                          </h5>

                          <p>
                            <Typography.Text strong>
                              Total Paid Amount :
                            </Typography.Text>{" "}
                            <strong>{totalPaidAmount}</strong>
                          </p>

                          <p>
                            <Typography.Text strong>
                              Total Return Amount:
                            </Typography.Text>{" "}
                            <strong>{totalReturnAmount}</strong>
                          </p>

                          <p>
                            <Typography.Text strong>
                              Due Amount :
                            </Typography.Text>{" "}
                            <strong style={{ color: "red" }}>
                              {dueAmount}
                            </strong>
                          </p>
                        </div>
                      </CardComponent>
                    </Badge.Ribbon>
                  </Col>
                  <Col span={12}>
                    <CardComponent title="Other Information">
                      <p>
                        <Typography.Text strong>
                          Supplier Name :
                        </Typography.Text>{" "}
                        <strong>{singlePurchaseInvoice.supplier?.name}</strong>
                      </p>

                      <p>
                        <Typography.Text strong>Phone :</Typography.Text>{" "}
                        <strong>{singlePurchaseInvoice.supplier?.phone}</strong>
                      </p>

                      <p>
                        <Typography.Text strong>Address :</Typography.Text>{" "}
                        <strong>
                          {singlePurchaseInvoice.supplier?.address}
                        </strong>
                      </p>
                      <br />
                      <hr />
                      <br />
                      <p>
                        <Typography.Text strong>Company Name :</Typography.Text>{" "}
                        <strong>{singlePurchaseInvoice.company?.name}</strong>
                      </p>
                      <p>
                        <Typography.Text strong>Gstin :</Typography.Text>{" "}
                        <strong>{singlePurchaseInvoice.company?.gstIn}</strong>
                      </p>
                      <p>
                        <Typography.Text strong>Phone :</Typography.Text>{" "}
                        <strong>{singlePurchaseInvoice.company?.phone}</strong>
                      </p>
                      <p>
                        <Typography.Text strong>Address :</Typography.Text>{" "}
                        <strong>
                          {singlePurchaseInvoice.company?.address}
                        </strong>
                      </p>
                      <br />
                      <hr />
                      <br />
                      <p>
                        <Typography.Text strong>
                          Warehouse Name :
                        </Typography.Text>{" "}
                        <strong>{singlePurchaseInvoice.warehouse?.name}</strong>
                      </p>
                      <p>
                        <Typography.Text strong>Address :</Typography.Text>{" "}
                        <strong>
                          {singlePurchaseInvoice.warehouse?.location}
                        </strong>
                      </p>
                      <br />
                      <hr />
                      <br />
                      <p>
                        <Typography.Text strong>Eway Bill :</Typography.Text>{" "}
                        <strong>{singlePurchaseInvoice.ewayBill}</strong>
                      </p>
                      <p>
                        <Typography.Text strong>
                          Transport Charge :
                        </Typography.Text>{" "}
                        <strong>{singlePurchaseInvoice.transportCharge}</strong>
                      </p>
                    </CardComponent>
                    {/* <br/>
										<CardComponent title='Company Information'>
											<p>
												<Typography.Text strong>
												Company Name :
												</Typography.Text>{" "}
												<strong>{singlePurchaseInvoice.company?.name}</strong>
											</p>

											<p>
												<Typography.Text strong>Phone :</Typography.Text>{" "}
												<strong>{singlePurchaseInvoice.company?.phone}</strong>
											</p>

											<p>
												<Typography.Text strong>Address :</Typography.Text>{" "}
												<strong>
													{singlePurchaseInvoice.company?.address}
												</strong>
											</p>
										</CardComponent> */}
                    {/* <br/>
										<CardComponent title='Warehouse Information'>
											<p>
												<Typography.Text strong>
												Warehouse Name :
												</Typography.Text>{" "}
												<strong>{singlePurchaseInvoice.warehouse?.name}</strong>
											</p>
											<p>
												<Typography.Text strong>Address :</Typography.Text>{" "}
												<strong>
													{singlePurchaseInvoice.warehouse ?.address}
												</strong>
											</p>
										</CardComponent> */}
                  </Col>
                </Row>
                <br />

                <PurchaseProductListCard
                  list={singlePurchaseInvoice.purchaseInvoiceProduct}
                />
                {/* <ReturnPurchaseInvoiceList list={returnPurchaseInvoice} />

								<TransactionPurchaseList list={transactions} /> */}
              </div>
            </Card>
          </Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default DetailsPurch;
