import { Navigate } from "react-router-dom";
import GetAllCompanies from "./getAllCompanies";

const CompaniesDetails = (props) => {
  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  return (
    <>
      <GetAllCompanies />
    </>
  );
};

export default CompaniesDetails;
