import { Link } from "react-router-dom";
import "./companiesDetails.css";

import { Card } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllProductBrand } from "../../redux/rtk/features/productBrand/productBrandSlice";
import ViewBtn from "../Buttons/ViewBtn";
import CreateDrawer from "../CommonUi/CreateDrawer";
import StatusSelection from "../CommonUi/StatusSelection";
import TableComponent from "../CommonUi/TableComponent";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";
import AddCompany from "./addCompany";
import { fetchCompanies } from "../../redux/rtk/features/company/companySlice";

const GetAllCompanies = (props) => {
  const dispatch = useDispatch();
  const { list, error, loading } = useSelector((state) => state.company);
  const columns = [
    {
      id: 1,
      title: "S No.",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, { id }) => (
        <Link to={`/admin/companiesdetails/${id}`}>{name}</Link>
      ),
    },
    {
      id: 3,
      title: "GSTIN",
      dataIndex: "gstIn",
      key: "gstIn",
    },
    {
      id: 4,
      title: "CIN",
      dataIndex: "cin",
      key: "cin",
    },
    {
      id: 5,
      title: "MSME",
      dataIndex: "hsn",
      key: "hsn",
    },
    {
      id: 6,
      title: "PAN",
      dataIndex: "pan",
      key: "pan",
    },
    {
      id: 7,
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      id: 8,
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      id: 9,
      title: "Website",
      dataIndex: "website",
      key: "website",
    },
    // {
    //   id: 7,
    //   title: "Bill Limit",
    //   dataIndex: "billLimit",
    //   key: "billLimit",
    // },
    // {
    //   id: 10,
    //   title: "Created At",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   render: (createdAt) => moment(createdAt).format("YYYY-MM-DD"),
    // },
    {
      id: 10,
      title: "Action",
      key: "action",
      render: ({ id }) => <ViewBtn path={`/admin/companiesdetails/${id}`} />,
    },
  ];
  useEffect(() => {
    dispatch(fetchCompanies());
    console.log(list, 'company data...')
  }, [dispatch]);

  return (
    <Card className='border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa]' bodyStyle={{ padding: 0 }}>
      <div className='md:flex items-center justify-between pb-3'>
        <h1 className='text-lg font-bold'>All Companies</h1>
        <div className='flex justify-between md:justify-start md:gap-3 gap-1 items-center'>
          {/* <StatusSelection paginatedThunk={loadAllProductBrand} /> */}
          <CreateDrawer
            permission={"create-productBrand"}
            title={"Add New Company"}
          >
            <AddCompany />
          </CreateDrawer>
        </div>
      </div>
      <UserPrivateComponent permission={"readAll-productBrand"}>
        <TableComponent
          columns={columns}
          csvFileName={"brand"}
          paginatedThunk={loadAllProductBrand}
          list={list}
          total={list.length}
          loading={loading}
        />
      </UserPrivateComponent>
    </Card>
  );
};

export default GetAllCompanies;
