import { Link, Navigate } from "react-router-dom";
import "./purchase.css";

import { Card, DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAllPurchase,
  loadAllPurchasen,
} from "../../redux/rtk/features/purchase/purchaseSlice";
import ViewBtn from "../Buttons/ViewBtn";
import PurchaseReport from "../Invoice/PurchaseReport";
import PurchaseRepo from "../Invoice/PurchaseRepo";
import PurchaseRepoAll from "../Invoice/PurchaseRepoAll";
import PurchaseRepoAllB from "../Invoice/PurchaseRepoAllB";
import TableComponent from "../CommonUi/TableComponent";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";
import { BtnBtn2 } from "../UI/BtnBtn2";
import CreateDrawer from "./../CommonUi/CreateDrawer";
import DashboardCard from "./Cards";
import AddPurch from "./addPurch";

const GetAllPurch = (props) => {
  const dispatch = useDispatch();
  const { list, listn, total, totaln, loading, information, informationn } =
    useSelector((state) => state.purchases);

  const plist = list?.allPurchaseInvoice || list;
  const nlist = list?.allPurchaseInvoice || listn;
  console.log(nlist);

  const [searchValue, setSearchValue] = useState("");
  const [alist, setAlist] = useState([]);
  const [blist, setBlist] = useState([]);
  const [searchValuen, setSearchValuen] = useState("");
  const { RangePicker } = DatePicker;
  const [startdate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [enddate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const columns = [
    {
      id: 1,
      title: "S No.",
      dataIndex: "id",
      key: "id",
      render: (name, { id }) => <Link to={`/admin/purchase/${id}`}>{id}</Link>,
      width: 70,
    },
    {
      id: 2,
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("ll"),
      width: 120,
    },
    {
      id: 3,
      title: "Supplier",
      dataIndex: `supplier`,
      key: "supplierId",
      render: (supplier) => supplier?.name,
      width: 140,
    },
    {
      id: 4,
      title: "Company",
      dataIndex: `company`,
      key: "companyId",
      render: (company) => company?.name,
      width: 140,
    },
    {
      id: 5,
      title: "Warehouse",
      dataIndex: `warehouse`,
      key: "warehouseId",
      render: (warehouse) => warehouse?.name,
      width: 140,
    },
    {
      id: 6,
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      id: 13,
      title: "Tax",
      dataIndex: "purchaseInvoiceVat",
      key: "vat",
      render: (purchaseInvoiceVat) => {
        if (purchaseInvoiceVat && purchaseInvoiceVat.length > 0) {
          return purchaseInvoiceVat.map((vat) => (
            <div key={vat.id}>
              {Array.isArray(vat.productVat) ? (
                vat.productVat.map((productVat) => (
                  <div key={productVat.id}>
                    {productVat.title}: {productVat.percentage}%
                  </div>
                ))
              ) : (
                <div key={vat.productVat.id}>
                  {vat.productVat.title}: {vat.productVat.percentage}%
                </div>
              )}
            </div>
          ));
        } else {
          return "N/A";
        }
      },
    },

    {
      id: 7,
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      id: 8,
      title: "Due",
      dataIndex: "dueAmount",
      key: "dueAmount",
    },
    {
      id: 9,
      title: "Paid",
      dataIndex: "paidAmount",
      key: "paidAmount",
    },
    // {
    //   id: 10,
    //   title: "Eway Bill",
    //   dataIndex: "ewayBill",
    //   key: "ewayBill",
    // },
    // {
    //   id: 11,
    //   title: "Transport Charge",
    //   dataIndex: "transportCharge",
    //   key: "transportCharge",
    // },
    {
      id: 12,
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, { dueAmount }) => (
        <div div className="flex flex-row">
          <ViewBtn path={`/admin/purchase/${id}`} />
          <Link
            to={dueAmount ? `/admin/payment/supplier/${id}` : "#"}
            state={{ dueAmount: dueAmount }}
          >
            {/* <BtnBtn2 className='' disabled={!dueAmount}>
              {" "}
              Payment
            </BtnBtn2> */}
          </Link>
        </div>
      ),
    },
  ];

  const columnsn = [
    {
      id: 1,
      title: "S No.",
      dataIndex: "id",
      key: "id",
      render: (name, { id }) => <Link to={`/admin/purchase/${id}`}>{id}</Link>,
      width: 70,
    },
    {
      id: 2,
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("ll"),
      width: 120,
    },
    {
      id: 3,
      title: "Product",
      dataIndex: `purchaseInvoiceProduct`,
      key: "product",
      render: (purchaseInvoiceProduct) => {
        const productName = purchaseInvoiceProduct[0]?.product?.name;
        return productName || "N/A";
      },
      width: 140,
    },
    {
      id: 4,
      title: "Qty",
      dataIndex: `purchaseInvoiceProduct`,
      key: "productQuantity",
      render: (qty) => {
        const pqty = qty[0]?.productQuantity;
        return pqty || "N/A";
      },
      width: 100,
    },
    {
      id: 5,
      title: "Amount",
      dataIndex: `purchaseInvoiceProduct`,
      key: "productPurchasePrice",
      render: (pp) => {
        const pqty = pp[0]?.productPurchasePrice;
        return pqty || "N/A";
      },
      width: 100,
    },
    {
      id: 6,
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      render: (supplier) => supplier?.name,
    },

    {
      id: 7,
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      id: 8,
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (company) => company?.name,
    },

    {
      id: 12,
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, { dueAmount }) => (
        <div div className="flex flex-row">
          <ViewBtn path={`/admin/purchase/${id}`} />
          <Link
            to={dueAmount ? `/admin/payment/supplier/${id}` : "#"}
            state={{ dueAmount: dueAmount }}
          >
            {/* <BtnBtn2 className='' disabled={!dueAmount}>
              {" "}
              Payment
            </BtnBtn2> */}
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const resp = await dispatch(
        loadAllPurchase({
          query: "all",
          page: 1,
          count: 10,
          startdate,
          enddate,
        })
      );
      setAlist(resp.payload.allPurchaseInvoice);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await dispatch(
        loadAllPurchasen({
          query: "all",
          page: 1,
          count: 10,
          startdate,
          enddate,
        })
      );
      console.log(resp);
      setBlist(resp?.payload?.purchaseInvoicesProducts);
    };

    fetchData();
  }, []);
  console.log(blist);

  useEffect(() => {
    dispatch(
      loadAllPurchase({
        status: true,
        page: 1,
        count: 10,
        startdate,
        enddate,
      })
    );
  }, [dispatch, enddate, startdate]);

  useEffect(() => {
    dispatch(
      loadAllPurchase({
        status: true,
        page: 1,
        count: 10,
        startdate,
        enddate,
        query: searchValue ? "search" : "all",
        purchase: searchValue,
      })
    );
  }, [dispatch, searchValue]);

  useEffect(() => {
    dispatch(
      loadAllPurchasen({
        status: true,
        page: 1,
        count: 10,
        startdate,
        enddate,
      })
    );
  }, [dispatch, enddate, startdate]);

  useEffect(() => {
    dispatch(
      loadAllPurchasen({
        status: true,
        page: 1,
        count: 10,
        startdate,
        enddate,
        query: searchValuen ? "search" : "all",
        purchase: searchValuen,
      })
    );
  }, [dispatch, searchValuen]);

  const onCalendarChange = (dates) => {
    const startdate = (dates?.[0]).format("YYYY-MM-DD");
    const enddate = (dates?.[1]).format("YYYY-MM-DD");
    setStartDate(startdate);
    setEndDate(enddate);
    dispatch(
      loadAllPurchase({
        status: true,
        page: 1,
        count: 10,
        startdate: startdate,
        enddate: enddate,
      })
    );
    dispatch(
      loadAllPurchasen({
        status: true,
        page: 1,
        count: 10,
        startdate: startdate,
        enddate: enddate,
      })
    );
  };

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchn = (e) => {
    setSearchValuen(e.target.value);
  };
  return (
    <>
      <div className="card card-custom mt-3 ">
        <div className="card-body">
          <div>
            <RangePicker
              onCalendarChange={onCalendarChange}
              defaultValue={[
                dayjs(startdate, "YYYY-MM-DD"),
                dayjs(enddate, "YYYY-MM-DD"),
              ]}
              className="range-picker"
              style={{ maxWidth: "400px" }}
            />
          </div>

          <DashboardCard information={information} count={total} />
          <br />
          <Card
            className="border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa]"
            bodyStyle={{ padding: 0 }}
          >
            <div className="flex items-center justify-between pb-3">
              <h1 className="text-lg font-bold">Purchase list</h1>
              <div className="justify-between md:justify-start flex gap-3 items-center">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={handleSearch}
                    className="border border-gray-300 rounded-md pl-10 pr-4 py-1 w-48"
                  />
                  <SearchOutlined className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
                </div>
                <PurchaseReport
                  data={plist}
                  date={{ startdate, enddate }}
                  // user={user}
                  total={total?._sum}
                />
                <PurchaseRepoAll data={alist} />
                <CreateDrawer
                  permission={"create-purchaseInvoice"}
                  title={"Add Purchase"}
                  width={70}
                >
                  <AddPurch />
                </CreateDrawer>
              </div>
            </div>
            <UserPrivateComponent permission={"readAll-purchaseInvoice"}>
              <TableComponent
                list={plist}
                total={total}
                columns={columns}
                loading={loading}
                paginatedThunk={loadAllPurchase}
                csvFileName={"purchase list"}
                query={{ startdate, enddate }}
              />
            </UserPrivateComponent>
          </Card>
          <br></br>
          <br></br>

          <Card
            className="border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa]"
            bodyStyle={{ padding: 0 }}
          >
            <div className="flex items-center justify-between pb-3">
              <h1 className="text-lg font-bold">
                Purchase List (According To Products)
              </h1>
              <div className="justify-between md:justify-start flex gap-3 items-center">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchValuen}
                    onChange={handleSearchn}
                    className="border border-gray-300 rounded-md pl-10 pr-4 py-1 w-48"
                  />
                  <SearchOutlined className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
                </div>
                <PurchaseRepo
                  data={nlist}
                  date={{ startdate, enddate }}
                  // user={user}
                  total={totaln?._sum}
                />
                <PurchaseRepoAllB data={blist} />

                {/* <CreateDrawer
                  permission={"create-purchaseInvoice"}
                  title={"Add Purchase"}
                  width={70}
                >
                  <AddPurch />
                </CreateDrawer> */}
              </div>
            </div>
            <UserPrivateComponent permission={"readAll-purchaseInvoice"}>
              <TableComponent
                list={nlist}
                total={totaln}
                columns={columnsn}
                loading={loading}
                paginatedThunk={loadAllPurchasen}
                csvFileName={"purchase list"}
                query={{ startdate, enddate }}
              />
            </UserPrivateComponent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default GetAllPurch;
