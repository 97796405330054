import { Button, Form, Input, message } from "antd";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import './warehouseDetails.css' 
import { updateWarehouse } from "../../redux/rtk/features/warehouse/warehouseSlice";

const UpdateWarehouseDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const singleWarehouse = useSelector((state) => state.warehouse.singleWarehouse);

  useEffect(() => {

  }, [dispatch, id]);

  const onFinish = async (values) => {
    try {
      await dispatch(updateWarehouse({ id, ...values }));
      message.success("Warehouse updated successfully");
      navigate(`/admin/warehouseDetails/${id}`);
    } catch (error) {
      message.error("Failed to update warehouse");
      console.log(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <h1 className="font-bold text-2xl mb-4">Update Warehouse</h1>
      <Form
        name="updateWarehouseForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={singleWarehouse}
      >
        
        <Form.Item
        className="mb-2"
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input the warehouse name!",
            },
          ]}
        >
          <Input className="mb-2"/>
        </Form.Item>

        <Form.Item
          label="Location"
          className=""
          name="location"
          rules={[
            {
              required: true,
              message: "Please input the location!",
            },
          ]}
        >
          <Input className=""/>
        </Form.Item>

        <Form.Item
        label=" "
        >
          <Button className="mt-5" type="primary" htmlType="submit">
            Update Warehouse
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default UpdateWarehouseDetails;
