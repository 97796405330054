import { useState } from "react";
import { Link } from "react-router-dom";
import "./product.css";

import { Card, Segmented } from "antd";
import { useEffect } from "react";

import {
  SolutionOutlined,
  UserDeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { loadProduct } from "../../redux/rtk/features/product/productSlice";
import ViewBtn from "../Buttons/ViewBtn";
import CreateDrawer from "../CommonUi/CreateDrawer";
import TableComponent from "../CommonUi/TableComponent";
import ProdReportPrint from "../Invoice/ProdReport";
import ProdReportAll from "../Invoice/ProdReportAll";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";
import AddProd from "./addProd";
import GenerateBarcodePopUp from "./generateBarcodePopUp";

const GetAllProd = (props) => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.products);
  const [searchValue, setSearchValue] = useState("");
  const [alist, setAlist] = useState([]);
  const rlist = list.getAllProduct || list;
  const totalPro = list?.totalProduct || list?.length;
  // console.log(list.totalProduct);
  // console.log(rlist);
  console.log(totalPro);
  const handleOnError = (e) => {
    e.target.src = "/images/default.jpg";
  };
  const columns = [
    // {
    //   id: 1,
    //   title: "Image",
    //   dataIndex: "thumbnailImageUrl",
    //   render: (thumbnailImageUrl) => (
    //     <div className='w-[2.5rem] h-[1.375rem] relative'>
    //       <img
    //         className='absolute object-cover w-full h-full'
    //         alt='product'
    //         onError={handleOnError}
    //         src={thumbnailImageUrl}
    //       />
    //     </div>
    //   ),
    //   key: "image",
    // },
    {
      id: 2,
      title: "S No.",
      dataIndex: "id",
      key: "id",
      // width: 70,
    },
    {
      id: 4,
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, { id }) => <Link to={`/admin/product/${id}`}>{name}</Link>,
      // width: 200,
    },
    {
      id: 6,
      title: "QTY",
      dataIndex: "productQuantity",
      key: "productQuantity",
      // width: 70,
    },
    {
      id: 15,
      title: "GST",
      dataIndex: "productVat",
      key: "productVat",
      responsive: ["md"],
      render: (productVat) => (productVat ? `${productVat}%` : null),
      // width: 70,
    },
    {
      id: 3,
      title: "HSN",
      dataIndex: "sku",
      key: "sku",
      // width: 100,
    },

    {
      id: 3,
      title: "M Sale Price",
      dataIndex: "productMinimumSalePrice",
      key: "productMinimumSalePrice",
      render: (item) => <>{item}</>,
      // width: 110,
    },

    // {
    //   id: 5,
    //   title: "U.M.",
    //   dataIndex: "unitMeasurement",
    //   key: "unitMeasurement",
    // },

    {
      id: 7,
      title: "P Price",
      dataIndex: "productPurchasePrice",
      key: "productPurchasePrice",
      responsive: ["md"],
      // width: 110,
    },
    {
      id: 8,
      title: "Sale Price",
      dataIndex: "productSalePrice",
      key: "productSalePrice",
      responsive: ["md"],
      // width: 110,
    },

    {
      id: 9,
      title: "Sub Category",
      dataIndex: "productSubCategory",
      key: "productSubCategory",
      render: (productSubCategory) => productSubCategory?.name,
      // width: 210,
    },
    {
      id: 10,
      title: "Brand",
      dataIndex: "productBrand",
      key: "productBrand",
      render: (productBrand) => productBrand?.name,
    },
    // {
    //   id: 11,
    //   title: "Unit Type",
    //   dataIndex: "unitType",
    //   key: "unitType",
    // },

    // {
    //   id: 12,
    //   title: "Reorder QTY",
    //   dataIndex: "reorderQuantity",
    //   key: "reorderQuantity",
    // },
    // {
    //   id: 13,
    //   title: "SAC",
    //   dataIndex: "sac",
    //   key: "sac",
    // },
    {
      id: 14,
      title: "Action",
      key: "action",
      render: ({ sku }, { id }) => (
        <div className="flex">
          <ViewBtn path={`/admin/product/${id}`} />
          {/* <GenerateBarcodePopUp sku={sku ? sku : 0} /> */}
        </div>
      ),
    },
  ];
  useEffect(() => {
    dispatch(
      loadProduct({
        status: "true",
        page: 1,
        count: 10,
        query: searchValue ? "search" : "all",
        key: searchValue,
      })
    );
  }, [dispatch, searchValue]);

  useEffect(() => {
    dispatch(loadProduct({ status: "true", page: 1, count: 10 }));
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await dispatch(
        loadProduct({
          query: searchValue ? "search" : "all",
          key: searchValue,
          page: 1,
          count: 10,
        })
      );
      setAlist(resp?.payload);
    };

    fetchData();
  }, [dispatch, searchValue]);

  const [status, setStatus] = useState("true");
  const onChange = (value) => {
    setStatus(value);
    dispatch(loadProduct({ query: "all", page: 1, count: 10 }));
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <Card
      className="border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa]"
      bodyStyle={{ padding: 0, overflowX: "scroll" }}
    >
      <div className="lg:flex items-center justify-between pb-3">
        <h1 className="text-lg font-bold">Product List</h1>
        <div className="flex gap-0  md:gap-5 items-center justify-between md:justify-start">
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              value={searchValue}
              onChange={handleSearch}
              className="border border-gray-300 rounded-md pl-10 pr-4 py-1 w-48"
            />
            <SearchOutlined className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
          </div>
          <ProdReportPrint
            data={rlist}
            // date={{ startdate, enddate }}
            // user={user}
            // total={total?._sum}
          />
          <ProdReportAll data={alist || []} />
          {/* <Segmented
            className="text-center rounded text-red-500 "
            size="middle"
            options={[
              {
                label: (
                  <span>
                    <SolutionOutlined /> Present
                  </span>
                ),
                value: "true",
              },
              {
                label: (
                  <span>
                    <UserDeleteOutlined /> Deleted
                  </span>
                ),
                value: "false",
              },
            ]}
            value={status}
            onChange={onChange}
          /> */}
          <CreateDrawer permission={"create-product"} title={"Create Product"}>
            <AddProd />
          </CreateDrawer>
        </div>
      </div>
      <UserPrivateComponent permission={"readAll-product"}>
        <TableComponent
          list={rlist}
          total={totalPro}
          loading={loading}
          columns={columns}
          csvFileName="Products"
          paginatedThunk={loadProduct}
        />
      </UserPrivateComponent>
    </Card>
  );
};

export default GetAllProd;
