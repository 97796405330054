import { Button, Form, Input, message } from "antd";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import './companiesDetails.css'
import { updateCompany } from "../../redux/rtk/features/company/companySlice";

const UpdateCompaniesDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const singleCompany = useSelector((state) => state.company.singleCompany);

  useEffect(() => {
  }, [dispatch, id]);

  const onFinish = async (values) => {
    try {
      await dispatch(updateCompany({ id, ...values }));
      // console.log(id)
      message.success("Company updated successfully");
      navigate(`/admin/companiesDetails/${id}`);
    } catch (error) {
      message.error("Failed to update company");
      console.log(values)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <h1 className="font-bold text-2xl mb-4">Update Company</h1>
      <Form
        name="updateCompanyForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={singleCompany}
      >
        <Form.Item
        className="mb-2"
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input the company name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="GST No."
          className="mb-2"
          name="gstIn"
          rules={[
            {
              required: true,
              message: "Please input the GST No.!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="MSME"
          className="mb-2"
          name="hsn"
          rules={[
            {
              required: true,
              message: "Please input the MSME!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="CIN No."
          className="mb-2"
          name="cin"
          rules={[
            {
              required: true,
              message: "Please input the CIN No.!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="PAN No."
          className="mb-2"
          name="pan"
          rules={[
            {
              required: true,
              message: "Please input the PAN No.!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
        className="mb-2"
          label="Phone"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input the phone number!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
        className="mb-2"
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "Please enter a valid email!",
            },
            {
              required: true,
              message: "Please input the email!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Website"
          className="mb-2"
          name="website"
          rules={[
            {
              required: true,
              message: "Please input the Website!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item
          label="Bill Limit"
          className="mb-2"
          name="billLimit"
          rules={[
            {
              required: true,
              message: "Please input the Bill Limit!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}

        <Form.Item
          label="Address"
          name="address"
          className="mb-2"
          rules={[
            {
              required: true,
              message: "Please input the Address!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Tagline"
          className="mb-2"
          name="tagLine"
          rules={[
            {
              required: true,
              message: "Please input the Tagline!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        

        <Form.Item className=""
          label=""
        >  
          <Button className="mt-5" type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default UpdateCompaniesDetails;
