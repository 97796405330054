import React from "react";
import { Button, Card, Form, Input, Typography } from "antd";
import styles from "./AddWarehouse.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addNewWarehouse } from "../../redux/rtk/features/warehouse/warehouseSlice";
import { useNavigate } from "react-router-dom";

const AddWarehouse = ({ drawer }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { Title } = Typography;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const formFields = [
    {
      label: 'Name',
      name: 'name',
      required: true,
      message: 'Please Enter Warehouse Name!',
      placeholder: 'Warehouse Name',
    },
    {
      label: 'Location',
      name: 'location',
      required: true,
      message: 'Please Input Warehouse Location!',
      placeholder: 'Location',
    },
  ];

  const validateName = (_, value) => {
    if (!/^[a-zA-Z ]+$/.test(value)) {
      return Promise.reject('Invalid Warehouse Name!');
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    try {
      console.log(values, 'values..');
      const body = { ...values, capacity: null }; 
      const resp = await dispatch(addNewWarehouse(body));

      if (resp.payload.message === "success") {
        setLoading(false);
        form.resetFields();
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    console.log("Failed:", errorInfo);
  };

  const onClick = () => {
    setLoading(true);
  };

  return (
    <>
      <Card bordered={false} className='criclebox h-full'>
        <Title level={4} className='m-3 text-center'>
          Add New Warehouse
        </Title>
        <Form
          form={form}
          className=''
          name='basic'
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          {formFields.map((item) => {
            return (
              <Form.Item
                key={item.name}
                style={{ marginBottom: '10px' }}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                  {
                    validator: item.name === 'name' ? validateName : undefined,
                  },
                ]}
              >
                <Input placeholder={item.placeholder} type={item.type ? item.type : 'text'} />
              </Form.Item>
            );
          })}

          <Form.Item
            style={{ marginBottom: '10px' }}
            className={styles.addProdBrandBtnContainer}
          >
            <Button
              type='primary'
              htmlType='submit'
              shape='round w-100'
              loading={loading}
              onClick={onClick}
            >
              Add Warehouse
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default AddWarehouse;
