import { Link, Navigate } from "react-router-dom";
import "./sale.css";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Form, Select } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAllSale,
  loadAllSaleRe,
  loadAllSalen,
} from "../../redux/rtk/features/sale/saleSlice";
import { loadAllStaff } from "../../redux/rtk/features/user/userSlice";
import ViewBtn from "../Buttons/ViewBtn";
// import { SearchOutlined } from "@ant-design/icons";
import DashboardCard from "../Card/DashboardCard";
import CreateDrawer from "../CommonUi/CreateDrawer";
import StatusSelection from "../CommonUi/StatusSelection";
import TableComponent from "../CommonUi/TableComponent";
import SaleReportPrint from "../Invoice/SaleReport";
import SaleReportPrintRe from "../Invoice/SaleReportRe";
import SaleRepo from "../Invoice/SaleRepo";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";
import { BtnBtn2 } from "../UI/BtnBtn2";
import AddSale from "./addSale";

const GetAllSale = (props) => {
  const dispatch = useDispatch();
  const {
    list,
    listn,
    totaln,
    listRe,
    total,
    loading: saleLoading,
  } = useSelector((state) => state.sales);
  const userList = useSelector((state) => state.users.list);
  const slist = list?.allSaleInvoice || list;
  const saleDataRe = listRe?.allSaleInvoice || listRe;
  const nlist = listn?.salesInvoicesProducts;
  console.log(nlist);
  let saleData = slist
    ? Array.isArray(slist)
      ? slist
      : Object.values(slist).filter((item) => typeof item === "object")
    : [];

  let saleDatan = nlist
    ? Array.isArray(nlist)
      ? nlist
      : Object.values(nlist).filter((item) => typeof item === "object")
    : [];
  const [user, setUser] = useState("");
  const [startdate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [enddate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchValuen, setSearchValuen] = useState("");

  const columns = [
    {
      id: 1,
      title: "Invoice",
      dataIndex: "id",
      key: "id",
      render: (name, { id }) => <Link to={`/admin/sale/${id}`}>{id}</Link>,
      width: 80,
    },
    {
      id: 2,
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("ll"),
      width: 120,
    },
    {
      id: 3,
      title: "Customer Name ",
      dataIndex: `customer`,
      key: "customerId",
      render: (customer) => customer?.name,
      width: 140,
    },

    {
      id: 4,
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: 130,
    },
    {
      id: 5,
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      width: 90,
    },
    {
      id: 6,
      title: "Due Amount",
      dataIndex: "dueAmount",
      key: "dueAmount",
      render: (dueAmount) => Number(dueAmount).toFixed(2),
      responsive: ["md"],
    },
    {
      id: 7,
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (paidAmount) => Number(paidAmount).toFixed(2),
      responsive: ["md"],
    },

    //Update Supplier Name here

    {
      id: 8,
      title: "Profit",
      dataIndex: "profit",
      key: "profit",
      render: (profit) => Number(profit).toFixed(2),
      responsive: ["md"],
    },
    {
      id: 9,
      title: "Sale Person",
      dataIndex: "user",
      key: "user",
      render: (user) => user?.username,
      responsive: ["md"],
    },
    {
      id: 10,
      title: "Action",
      dataIndex: "id",
      key: "payment",
      render: (id, { dueAmount }) => (
        <div className="flex ">
          <ViewBtn path={`/admin/sale/${id}`} />
          <Link
            to={dueAmount ? `/admin/payment/customer/${id}` : "#"}
            state={{ dueAmount: dueAmount || 0 }}
          >
            <BtnBtn2 className="btn btn-dark btn-sm " disabled={!dueAmount}>
              Pay
            </BtnBtn2>
          </Link>
        </div>
      ),
    },
  ];
  console.log(saleDatan);

  const columnsn = [
    {
      id: 1,
      title: "Invoice",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render: (name, { invoiceId }) => (
        <Link to={`/admin/sale/${invoiceId}`}>{invoiceId}</Link>
      ),
      width: 80,
    },
    {
      id: 2,
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).format("ll"),
      width: 120,
    },
    {
      id: 3,
      title: "Product",
      dataIndex: `product`,
      key: "name",
      render: (name) => {
        const pqty = name?.name;
        return pqty || "N/A";
      },
      width: 140,
    },

    {
      id: 4,
      title: "Qty",
      dataIndex: "productQuantity",
      key: "productQuantity",
      // render: (qty) => {
      //   const pqty = qty?.productQuantity;
      //   return pqty || "N/A";
      // },
      width: 70,
    },
    {
      id: 5,
      title: "Amount",
      dataIndex: "product",
      key: "product",
      render: (pro) => {
        const spri = pro?.productSalePrice;
        return spri || "N/A";
      },
      width: 90,
    },
    {
      id: 6,
      title: "Customer",
      dataIndex: "invoice",
      key: "invoice",
      render: (customer) => {
        const cName = customer?.customer?.name;
        return cName || "N/A";
      },
      width: 130,
    },
    {
      id: 7,
      title: "Agent",
      dataIndex: "invoice",
      key: "invoice",
      render: (customer) => {
        const cName = customer?.user?.username;
        return cName || "N/A";
      },
      width: 100,
    },

    //Update Supplier Name here

    {
      id: 8,
      title: "Total Amount",
      dataIndex: "invoice",
      key: "invoide",
      render: (customer) => {
        const cName = customer?.totalAmount;
        return cName || "N/A";
      },
      width: 90,
    },
    {
      id: 9,
      title: "Payout",
      dataIndex: "payout",
      key: "payout",
      width: 80,
    },
    {
      id: 11,
      title: "Payout Amount",
      dataIndex: "payoutAmount",
      key: "payoutAmount",
      width: 90,
    },
    {
      id: 12,
      title: "Company",
      dataIndex: "invoice",
      key: "invoide",
      render: (customer) => {
        const cName = customer?.company?.name;
        return cName || "N/A";
      },
      width: 120,
    },
    {
      id: 10,
      title: "Action",
      dataIndex: "id",
      key: "payment",
      render: (id, { dueAmount }) => (
        <div className="flex ">
          <ViewBtn path={`/admin/sale/${id}`} />
          <Link
            to={dueAmount ? `/admin/payment/customer/${id}` : "#"}
            state={{ dueAmount: dueAmount || 0 }}
          >
            <BtnBtn2 className="btn btn-dark btn-sm " disabled={!dueAmount}>
              Pay
            </BtnBtn2>
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(loadAllStaff({ status: true }));
  }, [dispatch]);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    dispatch(
      loadAllSale({
        page: 1,
        count: 10,
        startdate: startdate,
        enddate: enddate,
        user: "",
      })
    );

    dispatch(
      loadAllSaleRe({
        page: 1,
        count: 10,
        startdate: startdate,
        enddate: enddate,
        user: "",
      })
    );
  }, [dispatch, enddate, startdate]);

  useEffect(() => {
    dispatch(
      loadAllSale({
        page: 1,
        count: 10,
        startdate: startdate,
        enddate: enddate,
        user: "",
        query: searchValue ? "search" : "all",
        purchase: searchValue,
      })
    );

    dispatch(
      loadAllSaleRe({
        page: 1,
        count: 10,
        startdate: startdate,
        enddate: enddate,
        user: "",
        query: "all",
        purchase: searchValue,
      })
    );
  }, [dispatch, searchValue]);

  useEffect(() => {
    dispatch(
      loadAllSalen({
        page: 1,
        count: 10,
        startdate: startdate,
        enddate: enddate,
        user: "",
      })
    );
  }, [dispatch, enddate, startdate]);

  useEffect(() => {
    dispatch(
      loadAllSalen({
        page: 1,
        count: 10,
        startdate: startdate,
        enddate: enddate,
        user: "",
        query: searchValuen ? "search" : "all",
        purchase: searchValuen,
      })
    );
  }, [dispatch, searchValuen]);

  const onSearchFinish = (values) => {
    setUser(values?.user);
    const resp = dispatch(
      loadAllSale({
        page: 1,
        count: "",
        count: 10,
        startdate: startdate,
        enddate: enddate,
        user: values.user ? values.user : "",
      })
    );
    const respn = dispatch(
      loadAllSalen({
        page: 1,
        count: "",
        count: 10,
        startdate: startdate,
        enddate: enddate,
        user: values.user ? values.user : "",
      })
    );
    if (resp.payload.message === "success") {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const [form] = Form.useForm();

  const onCalendarChange = (dates) => {
    if (dates && dates.length === 2) {
      const startdate = dates[0].format("YYYY-MM-DD");
      const enddate = dates[1].format("YYYY-MM-DD");
      setStartDate(startdate);
      setEndDate(enddate);
    }
  };

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchn = (e) => {
    setSearchValuen(e.target.value);
  };

  console.log(saleData);

  return (
    <>
      <div className="card card-custom mt-2">
        <div className="card-body">
          <div className="card-title flex mt-1 py-2">
            <div className="">
              <Form
                onFinish={onSearchFinish}
                form={form}
                layout={"inline"}
                onFinishFailed={() => setLoading(false)}
              >
                <Form.Item name="user">
                  <Select
                    className="salelist-saleperson-input"
                    loading={!userList}
                    placeholder="Sale Person"
                    style={{ width: 200 }}
                    allowClear
                  >
                    <Select.Option value="">All</Select.Option>
                    {userList &&
                      userList.map((i) => (
                        <Select.Option value={i.id}>{i.username}</Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() => setLoading(true)}
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="salelist-search-btn btnHeight"
                  >
                    <SearchOutlined />
                  </Button>
                </Form.Item>
                <div className="mr-2">
                  <RangePicker
                    onCalendarChange={onCalendarChange}
                    defaultValue={[
                      dayjs(startdate, "YYYY-MM-DD"),
                      dayjs(enddate, "YYYY-MM-DD"),
                    ]}
                    className="range-picker"
                  />
                </div>
              </Form>
            </div>
          </div>
          <DashboardCard
            information={total?._sum ? Number(total?._sum).toFixed(3) : 0}
            count={total?._count}
            isCustomer={true}
          />
          <br />

          <Card
            className="border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa]"
            bodyStyle={{ padding: 0 }}
          >
            <div className="md:flex items-center justify-between pb-3">
              <h1 className="text-lg font-bold">Sale list</h1>
              <div className="flex justify-between md:justify-start gap-1 md:gap-3 items-center">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={handleSearch}
                    className="border border-gray-300 rounded-md pl-10 pr-4 py-1 w-48"
                  />
                  <SearchOutlined className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
                </div>
                {/* <StatusSelection
                  paginatedThunk={loadAllSale}
                  query={{ user, startdate, enddate }}
                /> */}
                <div className="xxs:w-1/2 md:w-full xxs:flex-col md:flex-row  flex xxs:gap-1">
                  <SaleReportPrint
                    data={saleData}
                    date={{ startdate, enddate }}
                    user={user}
                    total={total?._sum}
                  />
                  <SaleReportPrintRe
                    data={saleDataRe}
                    // date={{ startdate, enddate }}
                    // user={user}
                    // total={total?._sum}
                  />
                  <CreateDrawer
                    permission={"create-saleInvoice"}
                    title={"Add Sale"}
                    width={70}
                  >
                    <AddSale />
                  </CreateDrawer>
                </div>
              </div>
            </div>
            <UserPrivateComponent permission={"readAll-saleInvoice"}>
              <TableComponent
                list={saleData}
                columns={columns}
                loading={saleLoading}
                total={total?._count?.id}
                paginatedThunk={loadAllSale}
                csvFileName={"all sale"}
                query={{ startdate, enddate }}
              />
            </UserPrivateComponent>
          </Card>
          <br></br>
          <br></br>
          <Card
            className="border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa]"
            bodyStyle={{ padding: 0 }}
          >
            <div className="md:flex items-center justify-between pb-3">
              <h1 className="text-lg font-bold">
                Sale List (According To Product)
              </h1>
              <div className="flex justify-between md:justify-start gap-1 md:gap-3 items-center">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchValuen}
                    onChange={handleSearchn}
                    className="border border-gray-300 rounded-md pl-10 pr-4 py-1 w-48"
                  />
                  <SearchOutlined className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
                </div>
                {/* <StatusSelection
                  paginatedThunk={loadAllSale}
                  query={{ user, startdate, enddate }}
                /> */}
                <div className="xxs:w-1/2 md:w-full xxs:flex-col md:flex-row  flex xxs:gap-1">
                  <SaleRepo
                    data={nlist}
                    date={{ startdate, enddate }}
                    user={user}
                    total={totaln?._sum}
                  />
                  {/* <CreateDrawer
                    permission={"create-saleInvoice"}
                    title={"Add Sale"}
                    width={70}
                  >
                    <AddSale />
                  </CreateDrawer> */}
                </div>
              </div>
            </div>
            <UserPrivateComponent permission={"readAll-saleInvoice"}>
              <TableComponent
                list={saleDatan}
                columns={columnsn}
                loading={saleLoading}
                total={totaln?._count?.id}
                paginatedThunk={loadAllSalen}
                csvFileName={"all sale"}
                query={{ startdate, enddate }}
              />
            </UserPrivateComponent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default GetAllSale;
