import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  list: [],
  error: "",
  singleWarehouse: {},
  plist: {},
  loading: false,
};

export const fetchWarehouses = createAsyncThunk(
  "warehouse/fetchWarehouses",
  async () => {
    try {
      const { data } = await axios.get(
        `http://82.112.227.185:5000/warehouse`
      );
      const respData = {
        ...data,
        message: "success",
      };
      toast.success("Successfully Fetch Warehouses");
      console.log(respData);
      return respData;
    } catch (error) {
      toast.error("Error in Fetching Warehouses, Please Try Again!");
      console.log(error.message);
      throw error;
    }
  }
);

export const wproduct = createAsyncThunk(
  "warehouse/wproduct",
  async ({ id, query }, { rejectWithValue }) => {
    console.log(id);
    try {
      const { data } = await axios.get(
        `http://82.112.227.185:5000/warehouse/products/${id}`,
        {
          params: {
            search: query
          }
        }
      );
      const respData = {
        ...data,
        message: "success",
      };
      // toast.success("Fetched Successfully");
      return respData;
    } catch (error) {
      toast.error("Error in Fetching Products, Please Try Again!");
      console.log(error.message);
      throw error;
    }
  }
);


export const addNewWarehouse = createAsyncThunk(
  "warehouse/addNewWarehouse",
  async (body) => {
    try {
      const resp = await axios.post(
        `http://82.112.227.185:5000/warehouse`,
        body
      );
      toast.success("New Warehouse Created");
      return { ...resp.data, message: "success" };
    } catch (error) {
      console.log(error.message);
      return {
        status: false,
        error: error,
      };
    }
  }
);

export const updateWarehouse = createAsyncThunk(
  "updateWarehouse",
  async ({ id, ...body }, { rejectWithValue }) => {
    try {
      const resp = await axios.put(
        `http://82.112.227.185:5000/warehouse/${id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = resp.data;
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          throw new Error(
            `Error: ${error.response.status} - ${error.response.data.message}`
          );
        }

        throw new Error("Error! Try Again");
      } else if (error.request) {
        throw new Error("Error! Try Again");
      } else {
        throw new Error("Error! Try Again");
      }
    }
  }
);

export const deleteWarehouse = createAsyncThunk(
  "deleteWarehouse",
  async (id) => {
    try {
      const resp = await axios.delete(
        `http://82.112.227.185:5000/warehouse/${id}`
      );
      toast.success("Warehouse Deleted Successfully");
      return id;
    } catch (error) {
      toast.error("Error in Deleting Warehouse, Please Try Again!");
      console.log(error.message);
      throw error;
    }
  }
);

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  plist: {},
  reducers: {
    getWarehouseById: (state, action) => {
      return {
        ...state,
        singleWarehouse: state.list.find((item) => item.id == action.payload),
      };
    },
    clearSingleWarehouse: (state, action) => {
      return {
        ...state,
        singleWarehouse: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWarehouses.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchWarehouses.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload?.getAllWarehouses;
    });

    builder.addCase(fetchWarehouses.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
    builder.addCase(wproduct.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(wproduct.fulfilled, (state, action) => {
      state.loading = false;
      state.plist = action?.payload?.inventoryProduct;
      console.log(state.plist);
    });

    builder.addCase(wproduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(addNewWarehouse.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addNewWarehouse.fulfilled, (state, action) => {
      const { message, ...newWarehouse } = action.payload;
      state.loading = false;

      if (!Array.isArray(state.list)) {
        state.list = [];
      }
      const list = [...state.list];
      list.push(newWarehouse);
      state.list = list;
    });

    builder.addCase(addNewWarehouse.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

    builder.addCase(updateWarehouse.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateWarehouse.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.list.findIndex(
        (warehouse) => warehouse.id === action.payload.id
      );
      if (index !== -1) {
        state.list[index] = action.payload;
      }
    });

    builder.addCase(updateWarehouse.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(deleteWarehouse.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteWarehouse.fulfilled, (state, action) => {
      state.loading = false;
      state.list = state.list.filter(
        (warehouse) => warehouse.id !== action.payload
      );
    });

    builder.addCase(deleteWarehouse.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default warehouseSlice.reducer;
export const { getWarehouseById, clearSingleWarehouse } =
  warehouseSlice.actions;
