import moment from "moment";
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import { useReactToPrint } from "react-to-print";
import getSetting from "../../api/getSettings";
import { PrintAllBtn } from "../UI/CsvLinkBtn";
import * as XLSX from "xlsx";

import "./saleReport.module.css";

const PurchaseRepoAll = ({ data, date, user, total }) => {
  //   console.log(data);
  console.log(data);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [settingData, setsettingData] = useState(null);
  useEffect(() => {
    getSetting().then((data) => setsettingData(data.result));
  }, []);
  const exportToExcel = () => {
    const ws = XLSX.utils.table_to_sheet(componentRef.current);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Purchase_Report");
    XLSX.writeFile(wb, "purchase_report.xlsx");
  };

  return (
    <div>
      {data && (
        <div className="hidden">
          <div ref={componentRef}>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Date</th>
                  <th>Supplier</th>
                  <th>Company</th>
                  <th>Warehouse</th>
                  <th>Total</th>
                  <th>Tax</th>
                  <th>Discount</th>
                  <th>Due</th>
                  <th>Paid</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  Array.isArray(data) &&
                  data.map((item) => {
                    const date = new Date(item.date);

                    const formattedDate = `${date.getDate()}/${
                      date.getMonth() + 1
                    }/${date.getFullYear()}`;
                    return (
                      <tr key={item.id}>
                        <td>{item?.id}</td>
                        <td>{formattedDate}</td>
                        <td>{item?.supplier?.name}</td>
                        <td>{item?.company?.name}</td>
                        <td>{item?.warehouse?.name}</td>
                        <td>{item?.totalAmount}</td>
                        <td>
                          {item?.purchaseInvoiceVat[0]?.productVat?.title}
                        </td>
                        <td>{item?.discount}</td>
                        <td>{item?.dueAmount}</td>
                        <td>{item?.paidAmount}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {settingData && (
        <PrintAllBtn className="w-full mx-0 md:py-2 text-center">
          <button onClick={exportToExcel}>Export All</button>
        </PrintAllBtn>
      )}
    </div>
  );
};

export default PurchaseRepoAll;
