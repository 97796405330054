import { Link } from "react-router-dom";
import "./transferDetails.css";

import { Card, Input } from "antd";
import { Button, DatePicker, Form, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllStaff } from "../../redux/rtk/features/user/userSlice";
import axios from "axios";
import TransferReportPrint from "./TransferReportPrint";
import "../sale/sale.css";
import {
  SolutionOutlined,
  UserDeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  loadAllSale,
  loadAllSaleRe,
  loadAllTr,
} from "../../redux/rtk/features/sale/saleSlice";
import CreateDrawer from "../CommonUi/CreateDrawer";
import TableComponent from "../CommonUi/TableComponent";
import AddTransfer from "./addTransfer";
import { fetchCompanies } from "../../redux/rtk/features/company/companySlice";

const { Search } = Input;

const GetAllTransfer = (props) => {
  const dispatch = useDispatch();
  const { list, error, loading } = useSelector((state) => state.company);
  const [transferData, setTransferData] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [etransferData, setETransferData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loadingn, setLoadingn] = useState(false);
  const userList = useSelector((state) => state.users.list);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedfcompId, setSelectedfcompId] = useState("");
  const [selectedtcompId, setSelectedtcompId] = useState("");

  console.log(transferData);

  useEffect(() => {
    dispatch(loadAllStaff({ status: true }));
  }, [dispatch]);

  console.log(userList);
  const [companies, setCompanies] = useState([]);
  const [companiesn, setCompaniesn] = useState([]);
  const {
    listTr,
    totalTr,
    loading: saleLoading,
  } = useSelector((state) => state.sales);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(
          "http://82.112.227.185:5000/sale-invoice/companies?query=all"
        );

        if (response.data && response.data.length > 0) {
          setCompanies(response.data);
          setCompaniesn(response.data);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, []);

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Transferd From",
      dataIndex: "fromCompany",
      key: "fromCompany",
      render: (fromCompany) => fromCompany?.name,
    },
    {
      id: 3,
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (product) => product?.name,
    },
    {
      id: 4,
      title: "Quantity",
      dataIndex: "productQuantity",
      key: "productQuantity",
      render: (productQuantity) => productQuantity,
    },
    {
      id: 5,
      title: "Transferd To",
      dataIndex: "toCompany",
      key: "toCompany",
      render: (toCompany) => toCompany?.name,
    },
    {
      id: 7,
      title: "Done By",
      dataIndex: "user",
      key: "user",
      render: (user) => user?.username,
    },
    {
      id: 6,
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("YYYY-MM-DD"),
    },
  ];

  const handleUserSelect = (value) => {
    setSelectedUserId(value);
    console.log(value);
  };

  const handleFcompSelect = (value) => {
    setSelectedfcompId(value);
    console.log(value);
  };

  const handleTcompSelect = (value) => {
    setSelectedtcompId(value);
    console.log(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          limit: 10,
          query: searchValue ? "search" : "all",
          key: searchValue,
        };

        if (
          selectedUserId !== "" ||
          selectedfcompId !== "" ||
          selectedtcompId !== ""
        ) {
          params.query = "";
          params.user = selectedUserId;
          params.fromCompany = selectedfcompId;
          params.toCompany = selectedtcompId;
        }

        const response = await axios.get(
          "http://82.112.227.185:5000/inventory-transfer",
          { params }
        );
        setTransferData(
          response.data.paginatedTranserInvetory || response.data
        );
        setPagination(response);
        console.log(response);
        dispatch(loadAllTr({ params }));

        console.log(response.data || response.data.paginatedTranserInvetory);
      } catch (error) {
        console.error("Error fetching transfer data:", error);
      }
    };

    fetchData();
  }, [dispatch, searchValue, selectedUserId, selectedfcompId, selectedtcompId]);

  useEffect(() => {
    const fetchDataE = async () => {
      try {
        const params = {
          // count: 10,
          query: "all",
          key: searchValue,
        };

        if (
          selectedUserId !== "" ||
          selectedfcompId !== "" ||
          selectedtcompId !== ""
        ) {
          params.query = "all";
          params.user = selectedUserId;
          params.fromCompany = selectedfcompId;
          params.toCompany = selectedtcompId;
        }
        const response = await axios.get(
          "http://82.112.227.185:5000/inventory-transfer",
          { params }
        );
        setETransferData(
          response.data.paginatedTranserInvetory || response.data
        );

        console.log(response.data || response.data.paginatedTranserInvetory);
      } catch (error) {
        console.error("Error fetching transfer data:", error);
      }
    };

    fetchDataE();
  }, [dispatch, searchValue, selectedUserId, selectedfcompId, selectedtcompId]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  console.log(companies);

  return (
    <>
      <div className="card card-custom mt-2">
        <div className="card-body">
          <div className="card-title flex mt-1 py-2">
            <div className="">
              <Form onFinishFailed={() => setLoadingn(false)} className="flex">
                <Form.Item name="user">
                  <span>Done By: </span>
                  <br></br>
                  <Select
                    className="salelist-saleperson-input mr-2"
                    loading={!userList}
                    placeholder="Sale Person"
                    style={{ width: 200 }}
                    // allowClear
                    onChange={handleUserSelect}
                  >
                    <Select.Option value="">All</Select.Option>
                    {userList &&
                      userList.map((i) => (
                        <Select.Option key={i.id} value={i.id}>
                          {i.username}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item name="fromCompany">
                  <span>From Company: </span>
                  <br></br>
                  <Select
                    className="salelist-saleperson-input mr-2"
                    loading={!userList}
                    placeholder="From Company"
                    style={{ width: 200 }}
                    // allowClear
                    onChange={handleFcompSelect}
                  >
                    <Select.Option value="">All</Select.Option>
                    {companies &&
                      companies.map((i) => (
                        <Select.Option key={i.id} value={i.id}>
                          {i.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item name="toCompany">
                  <span>To Company: </span>
                  <br></br>
                  <Select
                    className="salelist-saleperson-input mr-2"
                    loading={!userList}
                    placeholder="To Company"
                    style={{ width: 200 }}
                    // allowClear
                    onChange={handleTcompSelect}
                  >
                    <Select.Option value="">All</Select.Option>
                    {companiesn &&
                      companiesn.map((i) => (
                        <Select.Option key={i.id} value={i.id}>
                          {i.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Card
        className="border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa]"
        bodyStyle={{ padding: 0 }}
      >
        <div className="md:flex items-center justify-between pb-3">
          <h1 className="text-lg font-bold">Inventory Transfer List</h1>
          <div className="flex justify-between md:justify-start md:gap-3 gap-1 items-center">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                value={searchValue}
                onChange={handleSearch}
                className="border border-gray-300 rounded-md pl-10 pr-4 py-1 w-48"
              />
              <SearchOutlined className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
            </div>

            <CreateDrawer
              permission={"create-productBrand"}
              title={"Add New Transfer"}
            >
              <AddTransfer />
            </CreateDrawer>
            <TransferReportPrint
              // onClick={handleExport}
              data={etransferData}
            />
          </div>
        </div>
        <TableComponent
          columns={columns}
          list={transferData}
          loading={loading}
          total={transferData?.length}
          paginatedThunk={loadAllTr}
        />
      </Card>
    </>
  );
};

export default GetAllTransfer;
