import { Link } from "react-router-dom";
import "./warehouseDetails.css"; 
import { Card } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllProductBrand } from "../../redux/rtk/features/productBrand/productBrandSlice";
import ViewBtn from "../Buttons/ViewBtn";
import CreateDrawer from "../CommonUi/CreateDrawer";
import TableComponent from "../CommonUi/TableComponent";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";
import AddWarehouse from "./addWarehouse";
import { fetchWarehouses } from "../../redux/rtk/features/warehouse/warehouseSlice"; 

const GetAllWarehouses = (props) => {
  const dispatch = useDispatch();
  const { list, error, loading } = useSelector((state) => state.warehouse); 
  const columns = [
    {
      id: 1,
      title: "S No.",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, { id }) => (
        <Link to={`/admin/warehousedetails/${id}`}>{name}</Link>
      ),
    },
    // {
    //   id: 3,
    //   title: "Capacity",
    //   dataIndex: "capacity",
    //   key: "capacity",
    // },
    {
      id: 4,
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      id: 5,
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("YYYY-MM-DD"),
    },
    {
      id: 6,
      title: "Action",
      key: "action",
      render: ({ id }) => <ViewBtn path={`/admin/warehousedetails/${id}`} />,
    },
  ];
  useEffect(() => {
    dispatch(fetchWarehouses());
    console.log(list, 'warehouse data...')
  }, [dispatch]);

  return (
    <Card className='border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa]' bodyStyle={{ padding: 0 }}>
      <div className='md:flex items-center justify-between pb-3'>
        <h1 className='text-lg font-bold'>All Warehouses</h1>
        <div className='flex justify-between md:justify-start md:gap-3 gap-1 items-center'>
          <CreateDrawer
            permission={"create-productBrand"}
            title={"Add New Warehouse"}
          >
            <AddWarehouse />
          </CreateDrawer>
        </div>
      </div>
      <UserPrivateComponent permission={"readAll-productBrand"}>
        <TableComponent
          columns={columns}
          csvFileName={"ware"}
          paginatedThunk={loadAllProductBrand}
          list={list}
          total={list.length}
          loading={loading}
        />
      </UserPrivateComponent>
    </Card>
  );
};

export default GetAllWarehouses;
