import { Link } from "react-router-dom";
import "./customer.css";

import { Card } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllCustomer } from "../../redux/rtk/features/customer/customerSlice";
import {
  SolutionOutlined,
  UserDeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import ViewBtn from "../Buttons/ViewBtn";
import CreateDrawer from "../CommonUi/CreateDrawer";
import StatusSelection from "../CommonUi/StatusSelection";
import TableComponent from "../CommonUi/TableComponent";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";
import AddCust from "./addCust";

const GetAllCustomer = (props) => {
  const dispatch = useDispatch();
  const { list, total, loading } = useSelector((state) => state.customers);
  const clist = list?.getAllCustomer || list;
  console.log(clist);
  const [searchValue, setSearchValue] = useState("");
  const columns = [
    {
      id: 1,
      title: "S No.",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, { id }) => (
        <Link to={`/admin/customer/${id}`}>{name}</Link>
      ),
    },
    {
      id: 3,
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      id: 5,
      title: "CIN",
      dataIndex: "cin",
      key: "cin",
    },
    {
      id: 6,
      title: "PAN",
      dataIndex: "pan",
      key: "pan",
    },
    // {
    //   id: 7,
    //   title: "HSN",
    //   dataIndex: "hsn",
    //   key: "hsn",
    // },
    {
      id: 8,
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
    },
    {
      id: 4,
      title: "Address",
      dataIndex: "address",
      key: "address",
      responsive: ["md"],
    },
    {
      id: 5,
      title: "Action",
      key: "action",
      render: ({ id }) => <ViewBtn path={`/admin/customer/${id}`} />,
    },
  ];

  useEffect(() => {
    dispatch(loadAllCustomer({ page: 1, count: 9999 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      loadAllCustomer({
        page: 1,
        count: 9999,
        query: searchValue ? "search" : "all",
        prod: searchValue,
      })
    );
  }, [dispatch, searchValue]);

  const [status, setStatus] = useState("true");
  const onChange = (value) => {
    setStatus(value);
    dispatch(loadAllCustomer({ page: 1, count: 9999, query: "all" }));
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <Card
      className="border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa]"
      bodyStyle={{ padding: 0 }}
    >
      <div className="md:flex items-center justify-between pb-3">
        <h1 className="text-lg font-bold">Customer</h1>
        <div className="flex justify-between md:justify-start gap-3 items-center">
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              value={searchValue}
              onChange={handleSearch}
              className="border border-gray-300 rounded-md pl-10 pr-4 py-1 w-48"
            />
            <SearchOutlined className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
          </div>
          {/* <StatusSelection paginatedThunk={loadAllCustomer} /> */}
          <CreateDrawer
            permission={"create-customer"}
            title={"Create Customer"}
          >
            <AddCust />
          </CreateDrawer>
        </div>
      </div>
      <UserPrivateComponent permission={"readAll-customer"}>
        <TableComponent
          columns={columns}
          csvFileName={"Customers"}
          paginatedThunk={loadAllCustomer}
          list={clist}
          total={total}
          loading={loading}
        />
      </UserPrivateComponent>
    </Card>
  );
};

export default GetAllCustomer;
