import { Button, DatePicker, Form, Input, InputNumber, Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllCustomer } from "../../redux/rtk/features/customer/customerSlice";
import {
  loadProduct,
  loadProductSale,
} from "../../redux/rtk/features/product/productSlice";
import { addSale } from "../../redux/rtk/features/sale/saleSlice";
// import Products from "./Products";
// import "./sale.css";
import dayjs from "dayjs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BigDrawer from "../Drawer/BigDrawer";
import AddCust from "../customer/addCust";
import getStaffId from "../../utils/getStaffId";

const AddTransfer = () => {
  const { Option } = Select;
  const [loader, setLoader] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState(null);
  // Form Function
  const [form] = Form.useForm();
  const [productQuantity, setProductQuantity] = useState(0);
  const { list: productList, loading: productLoading } = useSelector(
    (state) => state.products
  );
  console.log(productList);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  console.log(selectedCompanyId);
  const handleCompanyChange = (value) => {
    try {
      setSelectedCompanyId(value);
      setSelectedProductId(null);
      if (value) {
        form.resetFields(["toCompanyId", "productQuantity", "productId"]);
        dispatch({ type: "products/clearProducts" });

        console.log(value);

        dispatch(loadProductSale({ companyId: value }));
      }
    } catch (error) {
      console.error("Error handling company change:", error);
    }
  };

  const [companies, setCompanies] = useState([]);
  const [companiest, setCompaniest] = useState([]);
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(
          "http://82.112.227.185:5000/sale-invoice/companies?query=all"
        );

        if (response.data && response.data.length > 0) {
          setCompanies(response.data);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchCompaniest = async () => {
      try {
        const response = await axios.get(
          "http://82.112.227.185:5000/sale-invoice/companies?query=all"
        );

        if (response.data && response.data.length > 0) {
          setCompaniest(response.data);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompaniest();
  }, []);

  const onFormSubmit = async (values) => {
    try {
      const { fromCompanyId, toCompanyId, productId } = values;
      const productQuantity = parseInt(values.productQuantity);

      const userId = localStorage.getItem("id");
      const doneBy = parseInt(userId);

      const formData = {
        fromCompanyId,
        toCompanyId,
        productQuantity,
        productId,
        doneBy,
      };

      const response = await axios.post(
        "http://82.112.227.185:5000/inventory-transfer/",
        formData
      );

      console.log("Response from API:", response.data);

      form.resetFields();

      toast.success("Transfer Successful");
      window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoader(false);
      toast.error("Error");
    }
  };

  const handleProductChange = (productId, option) => {
    console.log(productId);
    setSelectedProduct(productId);
    const selectedProductQuantity = option.props.product.productQuantity;
    console.log(selectedProductQuantity);
    setProductQuantity(selectedProductQuantity);
  };

  // const handleProductChange = (productId) => {
  //   console.log(productId);
  //   const product = productList.find((prod) => prod.id === productId);
  //   setSelectedProduct(product);
  // };

  const validateProductQuantity = (_, value) => {
    if (!selectedProduct) return Promise.resolve();
    console.log(value);
    if (value > productQuantity || value == 0) {
      return Promise.reject(
        `Quantity Should Be More Than 0 & Less Then ${productQuantity}`
      );
    }
    return Promise.resolve();
  };

  return (
    <Form
      form={form}
      name="dynamic_form_nest_item"
      onFinish={onFormSubmit}
      onFinishFailed={() => {
        setLoader(false);
      }}
      layout="vertical"
      size="large"
      autoComplete="off"
      initialValues={{
        companyId: null,
      }}
    >
      <div className="flex gap-5">
        <Form.Item
          label="From Company"
          className="w-full"
          name="fromCompanyId"
          rules={[
            {
              required: true,
              message: "Please Select a Company!",
            },
          ]}
        >
          <Select
            className="w-full"
            showSearch
            placeholder="Select a company"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleCompanyChange}
          >
            {companies.map((company) => (
              <Option key={company.id} value={company.id}>
                {company.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <div className="flex gap-5">
        <Form.Item
          label="Product"
          className="w-full"
          name="productId"
          rules={[
            {
              required: true,
              message: "Please Select a Product!",
            },
          ]}
          // onChange={setSelectedProduct}
        >
          <Select
            className="w-full"
            showSearch
            placeholder="Select a Product"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onSelect={handleProductChange}
          >
            {productList?.map((product) => (
              <Option
                key={product.productId}
                value={product.productId}
                product={product}
              >
                {product?.product?.name +
                  "  " +
                  " (Available Quantity:" +
                  product.productQuantity +
                  ")"}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <div className="flex gap-5">
        <Form.Item
          required
          label="Transfer Quantity"
          className="w-full"
          name="productQuantity"
          rules={[
            {
              required: true,
              message: "Please enter Transfer Quantity",
            },
            {
              validator: validateProductQuantity,
            },
          ]}
        >
          <Input
            className="w-full"
            size="small"
            placeholder="Enter Transfer Quantity"
            style={{ marginBottom: "10px" }}
          />
        </Form.Item>
      </div>

      <div className="flex gap-5">
        <Form.Item
          label="To Company"
          className="w-full"
          name="toCompanyId"
          rules={[
            {
              required: true,
              message: "Please Select a Company!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const fromCompanyId = getFieldValue("fromCompanyId");
                if (value && value === fromCompanyId) {
                  return Promise.reject(
                    "From and To companies cannot be the same"
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            className="w-full"
            showSearch
            placeholder="Select a Company"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {companiest.map((company) => (
              <Option key={company.id} value={company.id}>
                {company.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item style={{ marginTop: "15px" }}>
        <Button
          disabled={loader}
          block
          type="primary"
          htmlType="submit"
          loading={loader}
        >
          Transfer Product
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddTransfer;
