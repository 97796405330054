import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  list: [],
  error: "",
  singleCompany: {},
  loading: false,
};

export const fetchCompanies = createAsyncThunk(
  "company/fetchCompanies",
  async () => {
    try {
      const { data } = await axios.get(`companies`);
      const respData = {
        ...data,
        message: "success",
      };
      toast.success("Successfully Fetch Companies");
      return respData;
    } catch (error) {
      toast.error("Error in fetching companies, please try again!");
      console.log(error.message);
      throw error;
    }
  }
);

export const addNewCompany = createAsyncThunk(
  "company/addNewCompany",
  async (body) => {
    try {
      const resp = await axios.post(`companies`, {
        ...body,
        billLimit: parseInt(body.billLimit),
      });
      toast.success("New Company Created");
      return { ...resp.data, message: "success" };
    } catch (error) {
      console.log(error.message);
      return {
        status: false,
        error: error,
      };
    }
  }
);

export const updateCompany = createAsyncThunk("updateCompany", async ({ id, ...body }, { rejectWithValue }) => {
	try {
	  const resp = await axios.put(`http://82.112.227.185:5000/companies/${id}`, body, {
		headers: {
		  "Content-Type": "application/json",
		},
	  });
  
	  const result = resp.data;
	  return result;
	} catch (error) {
	  if (axios.isAxiosError(error)) {
		
		if (error.response) {
		  throw new Error(`Error: ${error.response.status} - ${error.response.data.message}`);
		}
		
		throw new Error("Error 2nd");
	  } else if (error.request) {
		
		throw new Error("Fir Se Error");
	  } else {
		
		throw new Error("Error Dubara");
	  }
	}
  });

// export const updateCompany = createAsyncThunk(
//   "company/updateCompany",
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await axios.put(`companies/${data.id}`, data);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    getCompanyById: (state, action) => {
      return {
        ...state,
        singleCompany: state.list.find((item) => item.id == action.payload),
      };
    },
    clearSingleCompany: (state, action) => {
      return {
        ...state,
        singleCompany: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload?.getAllCompanies;
    });

    builder.addCase(fetchCompanies.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(addNewCompany.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addNewCompany.fulfilled, (state, action) => {
      const { message, ...newCompany } = action.payload;
      state.loading = false;

      if (!Array.isArray(state.list)) {
        state.list = [];
      }
      const list = [...state.list];
      list.push(newCompany);
      state.list = list;
    });

    builder.addCase(addNewCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

    builder.addCase(updateCompany.pending, (state) => {
      state.loading = true;
	  console.log(state)
    });

    builder.addCase(updateCompany.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.list.findIndex(
        (company) => company.id === action.payload.id
      );
      if (index !== -1) {
        state.list[index] = action.payload;
      }
    });

    builder.addCase(updateCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default companySlice.reducer;
export const { getCompanyById, clearSingleCompany } = companySlice.actions;
